
















































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import { AuthLogin, SelfResource } from '@/store/types'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import VKIDMixin from '@/mixins/VKIDMixin'
import { clearRedirectStorage } from '@/utils/functions'
import VKIDButton from '@/components/VKIDButton.vue'

@Component({
  components: {
    ButtonArrow,
    TextInput,
    VKIDButton,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class LoginForm extends Mixins(NotifyMixin, VKIDMixin) {
  private form: AuthLogin = {
    email: '',
    password: '',
  }
  private isFocusPassword = false
  private typePassword = true

  private mounted () {
    this.fetchVKIDData()
    const focusInput = ((this.$refs.focus as Vue).$el as HTMLElement).querySelector('input')

    if (focusInput) {
      focusInput.focus()
    }
  }

  @Debounce(300)
  @Bind
  private handleLogin () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          AuthModule.login(this.form)
            .then((response: SelfResource) => {
              this.$emit('birthDate', response.birthDate)
              this.$bus.$emit('socketUser')
              requestAnimationFrame(() => (form.reset()))

              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect.toString()).then(clearRedirectStorage)
              } else {
                if (response.permissions.length) {
                  this.$router.push({ name: 'manager' }).then(clearRedirectStorage)
                } else {
                  this.$router.push({ name: response.hasSocials ? 'master' : 'profile' }).then(clearRedirectStorage)
                }
              }
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
