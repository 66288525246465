import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'

@Component
export default class SocialResponseMixin extends Mixins(NotifyMixin) {
  protected checkSocialResponse () {
    if (this.$route.query.code) {
      switch (this.$route.query.code) {
      case '4001':
        this.notifyError('Почта в VK не заполнена. Заполните ее и повторите попытку')
        break
      case '4002':
        this.notifyError('Учетная запись VK уже привязана к другому профилю')
        break
      }
      this.$router.replace(this.$route.path)
    }
  }
}
