










import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LoginForm from '@/components/forms/auth/LoginForm.vue'
import SocialResponseMixin from '@/mixins/SocialResponseMixin'
import AuthModule from '@/store/modules/auth'
import { SelfResource, UserStatus } from '@/store/types'

@Component({
  components: { LoginForm },
})
export default class Login extends Mixins(SocialResponseMixin) {
  private isReady = false

  private get self () {
    return AuthModule.self
  }

  private mounted () {
    this.checkSocialResponse()

    AuthModule.fetchUser()
      .then((response: SelfResource) => {
        this.$emit('birthDate', response.birthDate)
        this.$bus.$emit('socketUser')
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect.toString())
            .catch(() => {return})
        } else {
          if (this.self && this.self.status.value === UserStatus.ACTIVE) {
            if (response.permissions.length) {
              this.$router.push({ name: 'manager' })
                .catch(() => {return})
            } else {
              this.$router.push({ name: 'master' })
                .catch(() => {return})
            }
          } else {
            this.$router.push({ name: 'profile' })
              .catch(() => {return})
            this.notifyError('Заполните все данные, чтобы полноценно работать с системой')
          }
        }
      })
      .catch(() => {
        this.isReady = true
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Вход в личный кабинет',
    }
  }
}
